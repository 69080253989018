














































































































































































































































































































































































































































































.amount {
  h2 {
    color: #000000;
    font-size: 16px;
    font-family: "Raleway Bold", serif;
    text-align: center;
  }
}

.employees {
  h3 {
    color: #000000;
    font-size: 16px;
    font-family: "Raleway Medium", serif;
  }
}

.el-input-number {
  width: 100%;
}

.amount-type-change {
  text-align: center;
}

.equals-buttons {
  button {
    height: 100%;
    min-height: 54px;
    white-space: normal;
  }
}

.reconciliation-excel__manually {
  text-align: center;
  margin: 20px;
  font-size: 3rem;
  color: #C0C4CC;
}
